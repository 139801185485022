import React from 'react';
import Slide from './Slide';


class About extends React.Component{

    render(){
        return (
            <div className="mainback">
                <div className="grad">
                <div className = "row pt-5 mx-1 about grey">
                    <h4><em>Partners For Process</em></h4>
                    <p>Partners for Process is a Father and Son company that helps Manufacturers with Automation Solutions. With over 30 Years of experience in dry material handling, Rich Sarow and Bruce Sarow can give honest advice in your process lines; helping you find replacement components for existing systems or Full Turn-Key Systems. Industries Served: Food, Pet Food, Plastics, Chemicals, Pharmaceutical's, Wood, Mining, Aggregates, and More.</p> 
                </div>
                <div className = "row">
                    <Slide />
                </div>
                </div>
            </div>
        )
    }
}

export default About;